/*
  Theme Name: Imperial
  Theme URL: https://bootstrapmade.com/imperial-free-onepage-bootstrap-template/
  Author: BootstrapMade
  Author URL: https://bootstrapmade.com
*/
/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/


body {
  background: black;
  color: #666666;
  font-family: "Open Sans", sans-serif;
}

a {
  color: #efb810;
  transition: 0.5s;
}

a:hover,
a:active,
a:focus {
  color: #efb810;
  outline: none;
  text-decoration: none;
}

p {
  padding: 0;
  margin: 0 0 30px 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
}

/* Prelaoder */
#preloader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow: visible;
  background: black url("./../img/preloader.svg") no-repeat center center;
}

/* Back to top button */
.back-to-top {
  position: fixed;
  display: none;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  padding: 6px 12px 9px 12px;
  font-size: 16px;
  border-radius: 2px;
  right: 15px;
  bottom: 15px;
  transition: background 0.5s;
}

section[id] {
  scroll-margin-top: 135px;
}

@media (max-width: 998px) {
  .back-to-top {
    bottom: 15px;
  }
}

.back-to-top:focus {
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  outline: none;
}

.back-to-top:hover {
  background: #efb810;
  color: #fff;
}

/*--------------------------------------------------------------
# Welcome
--------------------------------------------------------------*/
#hero {
  display: table;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: black;
}

#hero .hero-logo {
  margin: 20px;
}

#hero .hero-logo img {
  max-width: 40%;
}

#hero .hero-container {
  background: rgba(0, 0, 0, 0);
  display: table-cell;
  margin: 0;
  padding: 0;
  text-align: center;
  vertical-align: middle;
}

#hero .hero-container img{
  width: 100%;
}

#hero h1 {
  margin: 30px 0 10px 0;
  font-weight: 700;
  line-height: 48px;
  text-transform: uppercase;
  color: #fff;
}

@media (max-width: 998px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
}

#hero h2 {
  color: #999;
  margin-bottom: 50px;
}

@media (max-width: 998px) {
  #hero h2 {
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 30px;
  }

  #hero h2 .rotating {
    display: block;
  }
}

#hero .rotating>.animated {
  display: inline-block;
}

#hero .actions a {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 20px;
  border-radius: 2px;
  transition: 0.5s;
  margin: 10px;
}

#hero .btn-get-started {
  background: #efb810;
  border: 2px solid #efb810;
  color: black;
}

#hero .btn-get-started:hover {
  background: none;
  border: 2px solid #fff;
  color: #fff;
}

#hero .btn-services {
  border: 2px solid #fff;
  color: #fff;
}

#hero .btn-services:hover {
  background: #efb810;
  border: 2px solid #efb810;
  
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  background: black;
  padding: 0px 0;
  height: 135px;
  position: sticky;
  top: 0;
  z-index: 10;
}

#header #logo {
  float: left;
}

#header #logo h1 {
  font-size: 36px;
  margin: 0;
  padding: 6px 0;
  line-height: 1;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  letter-spacing: 3px;
  text-transform: uppercase;
}

#header #logo h1 a,
#header #logo h1 a:hover {
  color: #fff;
}

#header #logo img {
  padding: 0;
  margin: 0;
  max-height: 130px;
}

@media (max-width: 998px) {
  #header {
    height: 140px;
  }

  #header #logo h1 {
    font-size: 26px;
  }

  #header #logo img {
    max-height: 130px;
  }
}

.is-sticky #header {
  background: rgba(0, 0, 0, 1);
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Nav Menu Essentials */


.nav-menu>a {
  top: 0;
  left: 100%;
}

/* Nav Menu Arrows */
.sf-arrows .sf-with-ul {
  padding-right: 30px;
}

.sf-arrows .sf-with-ul:after {
  content: "\f107";
  position: absolute;
  right: 15px;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
}

.sf-arrows ul .sf-with-ul:after {
  content: "\f105";
}

/* Nav Meu Container */
#nav-menu-container {
  float: right;
  margin: 5px 0;
}

#nav-menu-container a {
  text-transform: capitalize;
}

@media (max-width: 998px) {
  #nav-menu-container {
    display: none;
  }
}

/* Nav Meu Styling */

.navbar-toggler {
  background-color: #efb810 !important;
}

.nav-menu>a {
  padding: 10px 15px;
  text-decoration: none;
  display: inline-block;
  color: #fff;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  font-size: 20px;
  outline: none;
  margin-inline-start: 2px;
}

.nav-menu>a:hover,
.nav-menu .active {
  color: #efb810 !important;
}

/* Mobile Nav Toggle */
#mobile-nav-toggle {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  margin: 20px 20px 0 0;
  border: 0;
  background: none;
  font-size: 24px;
  display: none;
  transition: all 0.4s;
  outline: none;
}

#mobile-nav-toggle i {
  color: #fff;
}

@media (max-width: 998px) {
  #mobile-nav-toggle {
    display: inline;
  }
}

/* Mobile Nav Styling */
#mobile-nav {
  position: fixed;
  top: 0;
  padding-top: 18px;
  bottom: 0;
  z-index: 998;
  background: rgba(0, 0, 0, 0.9);
  left: -260px;
  width: 260px;
  overflow-y: auto;
  transition: 0.4s;
}

#mobile-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#mobile-nav ul li {
  position: relative;
}

#mobile-nav ul li a {
  color: #fff;
  font-size: 16px;
  overflow: hidden;
  padding: 10px 22px 10px 15px;
  position: relative;
  text-decoration: none;
  width: 100%;
  display: block;
  outline: none;
}

#mobile-nav ul li a:hover {
  color: #fff;
}

#mobile-nav ul li li {
  padding-left: 30px;
}

#mobile-nav ul .menu-has-children i {
  position: absolute;
  right: 0;
  z-index: 99;
  padding: 15px;
  cursor: pointer;
  color: #fff;
}

#mobile-nav ul .menu-has-children i.fa-chevron-up {
  color: #efb810;
}

#mobile-nav ul .menu-item-active {
  color: #efb810;
}

#mobile-body-overly {
  width: 100%;
  height: 100%;
  z-index: 997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  display: none;
}

/* Mobile Nav body classes */
body.mobile-nav-active {
  overflow: hidden;
}

body.mobile-nav-active #mobile-nav {
  left: 0;
}

body.mobile-nav-active #mobile-nav-toggle {
  color: #fff;
}

/*--------------------------------------------------------------
# Sections
--------------------------------------------------------------*/
/* Sections Common
--------------------------------*/
.section-title {
  font-size: 32px;
  color: black;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
}

.section-description {
  text-align: justify;
  margin-bottom: 40px;
}

.section-title-divider {
  width: 50px;
  height: 3px;
  background: #efb810;
  margin: 0 auto;
  margin-bottom: 20px;
}

/* Get Started Section
--------------------------------*/
#about {
  background: #fff;
  padding: 80px 0;
}

#about .about-img {
  overflow: hidden;
}

#about .about-img img {
  max-width: 100%;
}

@media (max-width: 998px) {
  #about .about-img {
    height: auto;
  }

  #about .about-img img {
    margin-left: 0;
    padding-bottom: 30px;
  }
}

#about .about-content {
  background: #fff;
}

#about .about-title {
  color: #333;
  font-weight: 700;
  font-size: 28px;
}

#about .about-text {
  line-height: 26px;
  margin-bottom: 15px;
  text-align: justify;
}

#about .about-text:last-child {
  margin-bottom: 0;
}

/* Services Section
--------------------------------*/
#services {
  background: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url("./../img/services-bg.jpg") fixed center center;
  background-size: cover;
  padding: 80px 0 60px 0;
}

#services .service-item {
  margin-bottom: 20px;
}

#services .service-icon {
  float: left;
  background: black;
  padding: 16px;
  border-radius: 50%;
  transition: 0.5s;
}

#services .service-icon i {
  color: #efb810;
  font-size: 1.5rem;
}

#services .service-item:hover .service-icon {
  background: #efb810;
}

#services .service-item:hover .service-icon i {
  color: black;
}

#services .service-title {
  margin-left: 80px;
  font-weight: 700;
  margin-bottom: 15px;
  text-transform: uppercase;
}

#services .service-title a {
  color: black;
}

#services .service-description {
  margin-left: 80px;
  line-height: 24px;
}


#services .service-item h4{
  text-decoration: dashed;
}

/* Subscribe Section
--------------------------------*/
#subscribe {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(./../img/subscribe-bg.jpg) fixed center center;
  background-size: cover;
  padding: 80px 0;
}

#subscribe .subscribe-title {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

#subscribe .subscribe-text {
  color: #fff;
}

#subscribe .subscribe-btn-container {
  text-align: center;
  padding-top: 20px;
}

#subscribe .subscribe-btn {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 20px;
  border-radius: 2px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid #fff;
  color: #fff;
}

#subscribe .subscribe-btn:hover {
  background: #efb810;
  border: 2px solid #efb810;
}

/* Portfolio Section
--------------------------------*/
#portfolio {
  background: #fff;
  padding: 80px 0;
}

#portfolio .portfolio-item {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  height: 260px;
  width: 100%;
  display: table;
  overflow: hidden;
  margin-bottom: 30px;
}

#portfolio .portfolio-item .details {
  height: 260px;
  background: #fff;
  display: table-cell;
  vertical-align: middle;
  opacity: 0;
  transition: 0.3s;
  text-align: center;
}

#portfolio .portfolio-item .details h4 {
  font-size: 16px;
  transition: transform 0.3s, opacity 0.3s;
  transform: translate3d(0, -15px, 0);
  font-weight: 700;
  color: #333333;
}

#portfolio .portfolio-item .details span {
  display: block;
  color: #666666;
  font-size: 13px;
  transition: transform 0.3s, opacity 0.3s;
  transform: translate3d(0, 15px, 0);
}

#portfolio .portfolio-item:hover .details {
  opacity: 0.8;
}

#portfolio .portfolio-item:hover .details h4 {
  transform: translate3d(0, 0, 0);
}

#portfolio .portfolio-item:hover .details span {
  transform: translate3d(0, 0, 0);
}

/* Testimonials Section
--------------------------------*/
#productos {
  background: #fff;
  padding: 80px 0;
}

#productos .member {
  text-align: center;
  margin-bottom: 20px;
}

#productos .member .pic {
  margin-bottom: 15px;
  overflow: hidden;
  height: 260px;
}

#productos .member .pic img {
  max-width: 100%;
}

#productos .member h4 {
  font-weight: 700;
  margin-bottom: 2px;
}

#productos .member span {
  font-style: italic;
  display: block;
  font-size: 13px;
}

#productos .member .social {
  margin-top: 15px;
}

#productos .member .social a {
  color: #b3b3b3;
}

#productos .member .social a:hover {
  color: #efb810;
}

#productos .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

/* Team Section
--------------------------------*/
#team {
  background: #fff;
  padding: 80px 0 0 0;
}

#team .member {
  text-align: center;
  margin-bottom: 20px;
}

#team .member .pic {
  margin-bottom: 15px;
  overflow: hidden;
  height: 260px;
}

#team .member .pic img {
  max-width: 100%;
}

#team .member h4 {
  font-weight: 700;
  margin-bottom: 2px;
}

#team .member span {
  font-style: italic;
  display: block;
  font-size: 13px;
}

#team .member .social {
  margin-top: 15px;
}

#team .member .social a {
  color: #b3b3b3;
}

#team .member .social a:hover {
  color: #efb810;
}

#team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

/* Contact Section
--------------------------------*/
#contact {
  background: #f7f7f7;
  padding: 80px 0;
}

#contact .info {
  color: #333333;
}

#contact .info i {
  font-size: 32px;
  color: #efb810;
  float: left;
}

#contact .info p {
  padding: 0 0 10px 50px;
  line-height: 24px;
}

#contact .form #sendmessage {
  color: #efb810;
  border: 1px solid #efb810;
  display: none;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#contact .form #errormessage {
  color: red;
  display: none;
  border: 1px solid red;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#contact .form #sendmessage.show,
#contact .form #errormessage.show,
#contact .form .show {
  display: block;
}

#contact .form .validation {
  color: red;
  display: none;
  margin: 0 0 20px;
  font-weight: 400;
  font-size: 13px;
}

#contact .form input,
#contact .form textarea {
  border-radius: 0;
  box-shadow: none;
}

#contact .form button[type="submit"] {
  background: #efb810;
  border: 0;
  padding: 10px 24px;
  color: black;
  transition: 0.4s;
}

#contact .form button[type="submit"]:hover {
  background: black;
  color: white;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: black;
  padding: 30px 0;
  color: #fff;
}

#footer .copyright {
  text-align: center;
}

#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #ccc;
}

/*--------------------------------------------------------------
# Red social
--------------------------------------------------------------*/

.btn-facebook {
  display: block;
  width: 60px;
  height: 60px;
  color: black;
  position: fixed;
  right: 20px;
  bottom: 140px;
  border-radius: 50%;
  line-height: 80px;
  text-align: center;
  z-index: 999;
}

.btn-whatsapp {
  display: block;
  width: 60px;
  height: 60px;
  color: black;
  position: fixed;
  right: 20px;
  bottom: 180px;
  border-radius: 50%;
  line-height: 80px;
  text-align: center;
  z-index: 999;
}

.btn-instagram {
  display: block;
  width: 60px;
  height: 60px;
  color: black;
  position: fixed;
  right: 20px;
  bottom: 100px;
  border-radius: 50%;
  line-height: 80px;
  text-align: center;
  z-index: 999;
}

.btn-twitter {
  display: block;
  width: 60px;
  height: 60px;
  color: black;
  position: fixed;
  right: 20px;
  bottom: 60px;
  border-radius: 50%;
  line-height: 80px;
  text-align: center;
  z-index: 999;
}

#social img {
  width: 50%;
}

.header {
  background-color: #ffffff;
  padding: 10px 40px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}

.header>.logo-container {
  display: flex;
  align-items: center;
}

.header>.logo-container>.logo-img {
  width: 60px;
  height: 60px;
  margin-right: 15px;
}

.header>.logo-container>.logo-text {
  font-size: 26px;
  font-weight: 700;
}

.header>.logo-container>.logo-text>.logo-highlight {
  color: #65a9e5;
}

.content-container {
  width: 100%;
  height: calc(100vh - 89px);
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.active-users-panel {
  width: 300px;
  height: 100%;
  border-right: 1px solid #cddfe7;
}

.panel-title {
  margin: 10px 0 0 0;
  padding-left: 30px;
  font-weight: 500;
  font-size: 18px;
  border-bottom: 1px solid #cddfe7;
  padding-bottom: 10px;
}

.active-user {
  padding: 10px 30px;
  border-bottom: 1px solid #cddfe7;
  cursor: pointer;
  user-select: none;
}

.active-user:hover {
  background-color: #e8e9eb;
  transition: background-color 0.5s ease;
}

.active-user--selected {
  background-color: #fff;
  border-right: 5px solid #65a9e5;
  font-weight: 500;
  transition: all 0.5s ease;
}

.video-chat-container {
  padding: 0 20px;
  flex: 1;
  position: relative;
}

.talk-info {
  font-weight: 500;
  font-size: 21px;
}

.remote-video {
  border: 1px solid #cddfe7;
  width: 100%;
  height: 100%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}

.local-video {
  position: absolute;
  border: 1px solid #cddfe7;
  bottom: 60px;
  right: 40px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 300px;
}

.mapouter {
  position: relative;
  text-align: right;
  width: 600px;
  height: 300px;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: 600px;
  height: 300px;
}

.gmap_iframe {
  width: 600px !important;
  height: 300px !important;
}

.btn-add-product {
  color: #fff !important;
  background-color: black !important;
  border-radius: 0% !important;
}

.btn-sub-product {
  color: #fff !important;
  background-color: black !important;
  border-radius: 0% !important;
}

.btn-add-product:hover, .btn-sub-product:hover{
  background-color: #efb810 !important
}

.cursor {
  cursor: pointer;
}

.page-item.active .page-link {
  color: #fff;
  background-color: #efb810;
  border-radius: 0%;
  border-color: #efb810;
}

.carrusel-photos {
  max-width: 90vh;
  max-height: 90vh;
}

.carrusel-photos-img {
  width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.custom-nav {
  background-color: black;
  color: #cddfe7;
}

.active:hover {
  color: #efb810;
}


.nav-menu>a:hover, .nav-menu .active {
  /* Estilos para el enlace activo */
  font-weight: bold;
  border-bottom: solid 2px #efb810 ;
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 450px;
  height: 450px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}


@media (max-width: 992px) { 
  .circle{
    display: none;
  }
 }


@media (min-width: 992px) { 
  .order-inver{
    order: 2;
  }
 }

 .circle {
  height: 24px;
  width: 24px;
  border-radius: 24px;
  background-color: black;
  position: fixed; 
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 99999999;
}

.cursor-pointer{
  cursor: pointer;
}