.container-cart {
  all:initial;
  margin: auto;
  width: 80%;
  position: relative;
  float: left;
  color: white;
}

.shopping-cart {
  margin: 20px 0;
  background: black;
  width: 320px;
  position: absolute;
  border-radius: 3px;
  right: 0%;
}
.shopping-cart .shopping-cart-header {
  border-bottom: 1px solid #e8e8e8;
  padding: 20px;
  padding-bottom: 36px;
}
.shopping-cart .shopping-cart-header .shopping-cart-total {
  float: right;
}
.shopping-cart .shopping-cart-items {
  height: 300px;
  overflow-y: auto;
  padding-top: 20px;
  list-style-type: none;
}
.shopping-cart .shopping-cart-items li {
  margin-bottom: 18px;
}
.shopping-cart .shopping-cart-items img {
  float: left;
  margin-right: 12px;
}
.shopping-cart .shopping-cart-items .item-name {
  display: block;
  padding-top: 10px;
  font-size: 16px;
}
.shopping-cart .shopping-cart-items .item-price {
  color: #efb810;
  margin-right: 8px;
}
.shopping-cart .shopping-cart-items .item-quantity {
  color: #efb810;
}

.shopping-cart:after {
  bottom: 100%;
  left: 95%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: white;
  border-width: 8px;
  margin-left: -8px;
}

.lighter-text {
  color: white;
  margin-right: 5px;
}

.main-color-text {
  color: #efb810;
}

.btn-checkout{
  background-color: #efb810 !important;
  color: black !important;
}

.img-item-cart{
  width: 70px;
}

.pointer{
  cursor: pointer;
}